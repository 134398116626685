import { Component, OnInit, ViewChild, ViewChildren, Inject, PLATFORM_ID } from '@angular/core';
import { ICatagoryWithProduct, RentalProductsCatagory, IKitCatagory, IStandardProductCatagory } from '../shared/Interfaces/ILogin';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../shared/data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BannerComponent } from '../banner/banner.component';
import { Title, Meta } from '@angular/platform-browser';
import { SeoService } from '@miaguila/common';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  @ViewChild('bannerIdentifier', { static: true }) banner: BannerComponent;

  //@ViewChildren('singleRangeIdentifier') singleRangeChildComponents: QueryList<SingleRangeComponent>;

  CatagoryModel: ICatagoryWithProduct;
  public currentDeliveryCharge: string = "0";

  constructor(private seoService: SeoService, @Inject(PLATFORM_ID) private _platformId: Object, private titleService: Title, private meta: Meta, private route: ActivatedRoute, private router: Router, private dataService: DataService, private ngxLoader: NgxUiLoaderService) {

    if (isPlatformBrowser(this._platformId)) {
      // dataService.getCurrentCart('product');
      this.titleService.setTitle("TheRentalGuys.Ca - Product");
      try {
        if (isPlatformBrowser(this._platformId)) {
          let tmpThis = this;
          try {
            $(document).ready(function () {
              $('link[rel="canonical"]').remove()//.attr('href', 'NEW_HREF_GOES_HERE');

              tmpThis.seoService.generateCanonicalURL();
            });
          } catch (e) { }
        }
        //this.seoService.generateCanonicalURL();
      } catch (e) {

      }
    }
    
  }

  errorHandler(event, img) {
    console.debug(event);
    event.target.src = img;
  }

  
  IsPageLoaded = false;
  franchiseId;
  catagoryId;

  showRentals = true;
  showStandard = false;
  showKits = false;

  showRental() {
    this.showRentals = true;
    this.showStandard = false;
    this.showKits = false;
  }

  showKit() {
    this.showRentals = false;
    this.showStandard = false;
    this.showKits = true;
  }

  showStandards() {
    this.showRentals = false;
    this.showStandard = true;
    this.showKits = false;
  }

  selectProduct(item: RentalProductsCatagory): void {

    let prodName = '';
    try {
      prodName = item.shortName.replace(/\s/g, '').trim();
    } catch (e) {

    }


    if (item.isStandardRental) {
      localStorage.setItem("productId", item.productId.toString());
      this.router.navigate(["/standardRental", this.franchiseName, prodName]);
    }
    else {
      localStorage.setItem("productId", item.productId.toString());
      this.router.navigate(["/productdetails", this.franchiseName, prodName]);
    }
  }

  selectKProduct(item: IKitCatagory) {
    localStorage.setItem("kproductId", item.packageId.toString());

    let prodName = item.packageName.replace(/\s/g, '').trim();

    this.router.navigate(["/kit", prodName, item.packageId]);
  }

  selectSProduct(item: IStandardProductCatagory) {
    localStorage.setItem("sproductId", item.productId.toString());

    let prodName = item.name.replace(/\s/g, '').trim();

    this.router.navigate(["/standard", prodName, item.productId]);
  }

  franchiseName;
  catagoryName;
  loadBlog = false;

  getFullImage(img: string) {
    return `../../assets/theme/ProductImages/${img}`;
  }


  ngOnInit() {

    this.dataService.changeShowCartValue(false);
    
    this.franchiseName = (this.route.snapshot.params['city']).toString();

    try {
      localStorage.setItem('currentCity', this.franchiseName);
    } catch (e) {

    }

    setTimeout(() => {
      this.loadBlog = true;
    }, 5000);

    this.catagoryName = (this.route.snapshot.params['catagory']).toString();

    this.catagoryId = Number(localStorage.getItem("catagoryId"));
    this.franchiseId = Number(localStorage.getItem("franchiseId"));

    localStorage.removeItem("catagoryId");
    localStorage.removeItem("franchiseId");

    if (this.catagoryId != undefined && this.franchiseId != undefined && this.catagoryId != 0 && this.franchiseId != 0) {
      this.ngxLoader.start();
      this.dataService.getCatagoryProducts(this.catagoryId, this.franchiseId)
        .subscribe(
          (data: ICatagoryWithProduct) => {
            this.CatagoryModel = data;
            this.IsPageLoaded = true;

            try {
              this.titleService.setTitle(data.pageTitle);
              this.meta.updateTag({ name: 'keywords', content: this.CatagoryModel.keyword });
              this.meta.updateTag({ name: 'description', content: this.CatagoryModel.metaDescription });
            } catch (e) {

            }

            this.ngxLoader.stop();
          },
          (err: any) => {
            this.ngxLoader.stop();
            console.log(err);
          },
          () => {
            console.log("postalcode Data Recived");
          }
        );
    }
    else {
      this.ngxLoader.start();
      this.dataService.getCatagoryProductsWNames(this.catagoryName, this.franchiseName)
        .subscribe(
          (data: ICatagoryWithProduct) => {
            if (data == null) {
              this.router.navigate(["/"]);
            } else {
              this.CatagoryModel = data;
              try {
                this.titleService.setTitle(data.pageTitle);
                this.meta.updateTag({ name: 'keywords', content: this.CatagoryModel.keyword });
                this.meta.updateTag({ name: 'description', content: this.CatagoryModel.pageDescription });
              } catch (e) {

              }

              if (this.franchiseName.toLowerCase() == 'calgary') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "T3k");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              else if (this.franchiseName.toLowerCase() == 'edmonton') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "T5C");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              else if (this.franchiseName.toLowerCase() == 'vancouver') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "V7P");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              else if (this.franchiseName.toLowerCase() == 'guelph') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "N1C");
                localStorage.setItem("currentDeliveryCharge", "0");
              }
              else if (this.franchiseName.toLowerCase() == 'mississauga') {
                localStorage.setItem("currentDeliveryCity", this.franchiseName);
                localStorage.setItem("currentPostalCode", "L4X");
                localStorage.setItem("currentDeliveryCharge", "0");
              }

              this.currentDeliveryCharge = localStorage.getItem("currentDeliveryCharge");
              this.banner.generateBanner();
              this.IsPageLoaded = true;
            }
            this.ngxLoader.stop();
          },
          (err: any) => {
            this.ngxLoader.stop();
            console.log(err);
          },
          () => {
            console.log("postalcode Data Recived");
          }
        );
    }
    this.currentDeliveryCharge = localStorage.getItem("currentDeliveryCharge");
  }

}
