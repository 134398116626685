import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reviews-slider',
  templateUrl: './reviews-slider.component.html',
  styleUrls: ['./reviews-slider.component.css'],
  providers: [NgbCarouselConfig]
})
export class ReviewsSliderComponent implements OnInit {


  whatcustomerssay="../../assets/images/whatcustomerssay.png";
  stars="../../assets/images/star1.png";

   ngOnInit(): void {
  }

}
